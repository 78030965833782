@import "jose_modern/css";

.production-run-key {
  $swatch-size: 0.75em;

  .swatch {
    font-size: $size-6;
    display: inline-block;
    width: $swatch-size;
    height: $swatch-size;
    min-width: $swatch-size;
    border-radius: 2px;

    /* don't rely on baseline or auto margins: this has been chosen to
       scale to the containing font-size gracefully. */
    margin: auto 0.1em;

    &.closed {
      background-color: $green;
    }

    &.open {
      background-color: $red;
    }

    &.future {
      background-color: $yellow;
    }
  }
}
