@import "jose_modern/css";

html[data-view="pages#api_docs"] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h2 {
    font-size: $size-5;
  }

  #api-body {
    line-height: 1.7em;
  }

  #api-sidebar > p {
    padding-left: 1rem;
    font-weight: 600;
    color: $grey-light;
  }

  #api-sidebar .method-collection a {
    color: $dark;
    display: block;
    margin: 5px 0px;
    padding-left: 1.5rem;
    font-size: $size-6;
    font-weight: 400;
    text-decoration: none;
  }

  #api-sidebar .method-collection a.method-collection-title {
    margin-top: map-get($map: $spacing-values, $key: "4");
    padding-left: 1rem;
    font-size: $size-6;
    font-weight: 600;
  }

  #api-methods > .api-method > .method-section > .method-description {
    margin-top: map-get($map: $spacing-values, $key: "6");
    padding: map-get($map: $spacing-values, $key: "5");
  }

  #api-methods > .api-method > .method-section > .method-description > p {
    padding: 10px 0;
    margin: 0;
  }

  #api-methods > .api-method > .method-section > .method-example {
    font-size: $size-6;
    color: $white;
    background-color: $black-ter;

    /* can't use margin because of BG color, so triple-up padding-top */
    padding: map-get($map: $spacing-values, $key: "5");
    padding-top: 3 * map-get($map: $spacing-values, $key: "5");

    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.7);
  }

  #api-methods > .api-method > .method-section > .method-example > code {
    color: $white;
    display: block;
    font-family: $family-monospace;
    font-size: $size-7;
    word-break: break-word;
    background-color: transparent;
  }

  #api-methods code {
    .tab {
      padding-left: 1rem;
    }

    .tab2 {
      padding-left: 2rem;
    }

    .tab3 {
      padding-left: 3rem;
    }

    .tab4 {
      padding-left: 4rem;
    }

    .tab5 {
      padding-left: 5rem;
    }

    .tab6 {
      padding-left: 6rem;
    }
  }

  #api-methods > .api-method > .method-section > .method-example > p {
    color: $grey-lighter;
  }

  .api-method h2,
  .api-method h3,
  .api-method h4,
  .api-method h5 {
    margin-bottom: 5px;
  }

  .api-attribute,
  .api-definition {
    font-weight: 600;
  }

  .api-attribute {
    vertical-align: top;
    text-align: right;
    padding-left: map-get($map: $spacing-values, $key: "2");
    padding-right: 1em;
  }

  .api-definition {
    padding-bottom: 10px;
  }

  .api-definition > p {
    font-weight: 400;
    padding-top: 5px;
    margin: 0;
  }

  @include desktop {
    #api-docs {
      padding-top: 2rem;
      display: flex;
    }

    #api-sidebar {
      transform: translateZ(0);
      position: sticky;
      display: block;
      background: transparent;
      align-self: flex-start;
      top: 0;
      overflow-y: scroll;

      min-width: 260px;
      padding-left: 1em;

      height: 100vh;
      padding-top: 1rem;

      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Give desktop users on macOS a hint that this is a scrolling container */
      &:hover::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &:hover::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    #api-methods {
      .api-method {
        margin-top: 0;
        .method-section {
          display: flex;
          flex-direction: row;

          .method-description {
            width: 50%;
            border-bottom: 1px solid $grey-lighter;
          }

          .method-example {
            width: 50%;
            border-bottom: 1px solid $grey;
            overflow-x: scroll;
          }
        }
      }
    }
  }

  @include touch {
    #api-docs {
      padding-top: 2rem;
      display: block;
    }

    #api-sidebar {
      display: block;
      clear: both;
      background: transparent;
      min-width: 280px;
      position: initial;

      a {
        padding-left: 0;
        margin-left: 1rem;
      }

      .method-collections {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    #api-body {
      display: block;
      clear: both;
    }

    #api-methods {
      .api-method {
        display: block;
        .method-section {
          padding-top: 0;
          .method-description {
            margin-top: 0;
          }

          .method-example {
            margin: map-get($map: $spacing-values, $key: "6") 1rem;
            padding: map-get($map: $spacing-values, $key: "6") 30px;
          }
        }
      }
    }
  }
}
