@import "jose_modern/css";

// Glide css will get purged without this
/*! purgecss start ignore */
@mixin has-blob($variant: "blob-1") {
  background: url("~pages/home/images/#{$variant}.svg") no-repeat scroll top center;
  background-size: contain;
}

html[data-view="pages#home"] {
  .title {
    font-size: $size-2;
  }

  blockquote {
    border-left-color: lighten($color: $primary, $amount: 7%);
  }

  #top-hero {
    .has-blob {
      img {
        @include has-blob;
        background-position-y: 25px;
      }
    }

    .top-hero-subheader {
      font-size: 1.1rem;
    }
  }

  #features-one {
    @include touch {
      margin-left: map-get($map: $spacing-values, $key: "6");
      margin-right: map-get($map: $spacing-values, $key: "6");
    }

    a {
      display: block;
      color: $black;
      &:hover {
        color: $link-hover;
      }
    }
  }

  #nutrition-analysis-made-easy {
    .has-blob {
      @include has-blob("blob-5");
      background-position: 50%;
      img {
        box-shadow: $box-shadow-sharp;
      }
    }
  }

  #nutrition-labeling-thats-fun {
    .has-blob {
      @include has-blob("blob-3");
      background-position: 50%;
      img {
        box-shadow: $box-shadow-sharp;
      }
    }
  }

  #recipe-costing-to-make-decisions {
    .has-blob {
      @include has-blob("blob-4");
      background-position: 50%;

      @include mobile {
        background-size: 80%;
      }
    }
  }

  #features-two {
    @include tablet {
      .icon-box {
        min-height: 72px;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  #customer-service-to-die-for {
    @include has-blob("blob-2");
    background-position-y: 125px;
    background-size: auto 60%;
  }

  #food-entrepreneurs {
    #industries-list {
      .card {
        height: 100%;
        display: flex;
        flex-direction: column;

        transform: scale(1);
        will-change: transform;
        &:hover {
          transform: scale(1.03);
          transition: transform 0.2s ease-in-out;
        }
      }

      .card-content {
        padding: 0;
        a {
          display: block;
          padding: 24px;
        }
      }

      @include mobile {
        overflow-x: scroll;
        .card-image {
          width: 148px;
          height: 148px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  #case-studies {
    /* Case studies "crosshair" */
    #case-studies-grid {
      /* We want our gridlines only if we haven't been collapsed: tablet device and up */
      @include tablet {
        & > .columns:first-child {
          border-bottom: 1px solid $grey-lighter;
        }

        & > .columns > .column:first-child {
          border-right: 1px solid $grey-lighter;
        }
      }

      .case-study {
        margin: map-get($spacing-values, "5") map-get($spacing-values, "5");
        blockquote {
          font-family: $family-secondary;
          font-style: italic;
          color: $grey;
        }

        @include mobile {
          img {
            max-width: 220px;
          }

          p {
            display: block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }

  #testimonials {
    .testimonial {
      font-size: $size-6;
      margin-left: map-get($spacing-values, "3");
      margin-right: map-get($spacing-values, "3");
      padding-top: map-get($spacing-values, "1");

      blockquote {
        background-color: $white;
      }

      p {
        font-family: $family-secondary;
        font-style: italic;
        color: $grey;
      }

      strong {
        font-family: $family-secondary;
        display: block;
        margin-top: map-get($spacing-values, "5");
        color: $grey-darker;
      }
    }
  }

  #sign-up-for-free {
    margin-bottom: 0 !important;
    .has-blob {
      @include has-blob("blob-5");
      background-position-y: 50%;
      background-size: 100% 200%;
    }
  }

  .testimonial-image-container {
    height: 200px;

    a {
      line-height: 200px;
      vertical-align: middle;

      img {
        vertical-align: middle;
        max-height: 100%;
      }
    }
  }

  .glide__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;

    .glide__arrow {
      font-size: 1.5rem;
      color: $grey;
      cursor: pointer;

      &:focus,
      &:hover {
        font-size: 1.75rem;
        color: $primary;
      }
    }

    .glide__bullets {
      display: flex;
      gap: 10px;
      align-items: center;

      .glide__bullet {
        font-size: 1.5rem;
        color: $grey;
        cursor: pointer;

        &:focus,
        &:hover {
          color: $primary;
        }

        // This won't get picked up unless purgecss ignores.
        // Purgecss ignoring from top of file for this because I couldn't get it to work here
        &.glide__bullet--active {
          font-size: 1.75rem;
          color: $primary;
        }
      }
    }
  }
}
/*! purgecss end ignore */
