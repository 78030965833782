@import "jose_modern/css";
/* This pricing table can be configured here.  Here are the variables we have access to before
   importing:
   https://github.com/Wikiki/bulma-pricingtable/blob/master/src/sass/index.sass
*/
@import "~bulma-pricingtable/src/sass";

@mixin has-blob($variant: "blob-1") {
  background: url("~pages/home/images/#{$variant}.svg") no-repeat scroll top center;
  background-size: contain;
}

html[data-view="pages#pricing"],
html[data-view="pages#expert"],
html[data-view="pages#inventory_pricing"] {
  section.pricing-nav {
    padding-bottom: 0;
  }

  .price-section-header {
    padding-top: 48px * 2;
    padding-bottom: 48px;
    background-color: $white-ter;
  }

  .price-section {
    padding-top: 1.5rem;
    padding-bottom: 10rem;
    margin-bottom: 5rem;
    background-color: $white-ter;
    border-bottom: 2px solid $grey-lighter;
  }

  #still-have-questions {
    @include has-blob("blob-5");
    background-position-y: 50%;
    background-size: 100% 200%;
  }

  .pricing-table {
    justify-content: center;

    .plan-item .content,
    .plan-item .long {
      display: inline-block;
      max-width: 20em;
    }

    .pricing-plan li {
      margin-bottom: 0.75rem;
    }

    .pricing-plan.is-active .plan-price .plan-price-amount {
      color: $black;
    }

    .pricing-plan {
      position: relative;
      padding: 2em 0.5em;
      margin: 0.5em 1em;
      box-shadow: $box-shadow;

      /* the bouncy effect actually looks weird on slower-than-instant loading */
      transition: none;

      @include touch {
        /* disable the automatic scale is-active plan */
        &.is-active {
          transform: scale(1);
          box-shadow: $box-shadow;
        }
      }

      @include tablet {
        max-width: 25em;
      }

      .tag {
        background-color: $secondary;
        color: $white;
        width: auto;
        position: absolute;
        right: 2em;
        font-size: $size-7;
        font-weight: bold;
      }

      a.button {
        font-weight: bold;
        font-size: $size-6;
      }

      .plan-header {
        text-align: left;
        margin-top: 2rem;
        font-family: $family-secondary;
      }

      .plan-subtitle {
        color: $grey;
        font-size: $size-6;
        margin-top: -1rem;
        margin-left: 1em;
      }

      .plan-per {
        margin-left: 1em;
        margin-top: -0.25em;
        color: $grey;
        font-size: $size-6;
      }

      .plan-price {
        text-align: left;
        .plan-price-amount {
          color: $black;
          font-weight: 900;
          font-size: $size-1;
          .plan-price-currency {
            color: $secondary;

            &:last-child {
              color: $grey;
              margin-left: 0.2em;
              vertical-align: baseline;
            }
          }
        }
      }

      .plan-items .plan-item {
        background-color: $white;
        color: $black;
        text-align: left;

        display: flex;

        padding: 0.5em 0.75em;

        .icon {
          margin-right: 0.5em;
          color: $success;
        }

        span {
          small {
            color: $grey;
            font-style: italic;
          }
        }
      }

      .plan-footer {
        background-color: $white-bis;
        margin-top: 2em;
        margin-bottom: -36px;
        margin-left: -8px;
        margin-right: -10px;
      }
    }
  }
}

.plan-price {
  text-align: left;
  .plan-price-amount {
    color: $black;
    font-weight: 900;
    font-size: $size-1;
    .plan-price-currency {
      color: $secondary;
      vertical-align: super;
      font-size: $size-6;
      font-weight: 400;

      &:last-child {
        color: $grey;
        margin-left: 0.2em;
        vertical-align: baseline;
      }
    }
  }
}

.satisfaction-badge {
  @include touch {
    display: block;
    text-align: center;

    img {
      padding-bottom: map-get($map: $spacing-values, $key: "6");
    }
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: map-get($map: $spacing-values, $key: "5");
    }
  }
}
