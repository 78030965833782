@import "jose_modern/css";

#features-top-hero {
  background: url("~pages/home/images/blob-5.svg") no-repeat scroll top center;
  background-size: 80%;
  background-position-y: 50%;
  background-position-x: 20%;
}

html[data-view^="pages#"] {
  .features-center-blob {
    background: url("~pages/home/images/blob-5.svg") no-repeat scroll top center;
    background-size: 80%;
    background-position-y: 50%;
  }

  .features-spacer {
    margin: 2.5rem 0;
    width: 85px;
    height: 8px;
  }

  blockquote {
    border-left-color: lighten($color: $primary, $amount: 7%);

    span {
      display: block;
      font-size: small;
    }
  }
}
