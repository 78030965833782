@import "~jose_modern/css";

.blog-content {
  font-size: $size-6;
  line-height: 1.6em;

  .heading {
    text-transform: none;
    font-size: $size-4;
    font-weight: bold;
    display: block;
    margin-top: map-get($spacing-values, "6");
    letter-spacing: 0;
  }

  .pull-right {
    display: block;
    float: right;
    margin: 1rem 2rem;
  }

  .bold {
    font-weight: bold;
  }

  .quote {
    display: block;
    font-style: italic;
    margin-left: 2em;
    margin-right: 2em;
    padding: 1em 2em;
    background-color: $light;
  }

  .caption {
    font-size: $size-7;
    font-weight: bold;
    display: block;
    text-align: center;
  }

  img {
    display: block;
    margin: 1em auto;
  }

  p {
    margin-top: 1em;
  }
}

html[data-view^="blogs#"] {
  #blog-section {
    background: $white-bis;
  }

  h1.title {
    line-height: 1.4em;
  }

  h2.subtitle {
    font-family: $family-secondary;
  }

  .hover-extension {
    position: relative;
    .hover-content {
      display: none;
    }

    &:focus {
      .hover-content {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 360px;
      }
    }
  }

  #blog-sidebar {
    background: $white;
    height: 100%;
  }

  .blog-heading {
    color: $grey-darker;
  }

  .pagination-previous,
  .pagination-next {
    background: $white;
  }

  @include touch {
    .tabs.collapse {
      a {
        border-bottom: none;
      }
    }
  }
}

.blog-sidebar-author {
  color: $grey-darker;
  font-size: 0.9rem !important;
}

.blog-thumbnail {
  object-fit: cover;
  object-position: center top;
}

#blog-cta {
  background: url("~pages/home/images/blob-5.svg") no-repeat scroll top center;
  background-position-y: 50%;
  background-size: 100% 180%;
}
