@import "jose_modern/css";

html[data-view="pages#faq"],
html[data-view="pages#faq_inventory"] {
  /* Sticky only when not side-by-side */
  @include desktop {
    .faq-sidebar {
      background-color: $white-bis;

      margin-top: 0em;
      margin-right: 2em;

      padding: 2em 1em 2em 2em;

      transform: translateZ(0);
      position: sticky;
      align-self: flex-start;
      top: 0;
      overflow-y: scroll;
      height: 100vh;

      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Give desktop users on macOS a hint that this is a scrolling container */
      &:hover::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &:hover::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .faq-sidebar a {
    display: block;
    line-height: 1.7em;
  }

  .faq-sidebar h4 {
    font-weight: bold;
    @include desktop {
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  .faq-sidebar .faq-sidebar-category {
    @include desktop {
      padding-top: 0;
      margin-top: 0;
    }

    margin-bottom: 1rem;
  }

  .faq-content > div {
    /* Make pulses smoother */
    will-change: transform;
  }

  .faq-content h2 {
    font-weight: bold;
    font-size: $size-4;
    color: $dark;
  }

  .faq-content div {
    margin-bottom: 3rem;
  }

  .faq-content h3 {
    font-weight: bold;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: $size-5;
  }

  .faq-content p {
    margin-bottom: 1rem;
  }
}
