@import "jose_modern/css";

html[data-view="pages#demos"] {
  h2.subtitle {
    font-size: $size-6;
    margin-top: map-get($spacing-values, "6");
    padding-top: map-get($spacing-values, "6");

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
