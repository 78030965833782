@import "~jose_modern/css";

html[data-view="pages#about"] {
  section {
    perspective: 800px;
  }

  #space-lev {
    img {
      border-radius: $radius;
    }

    pointer-events: none;
    transform: scale(1) translateX(0) rotateY(0) rotateZ(0);
    opacity: 1;
    will-change: transform, opacity;
    transition:
      transform 1s ease-out,
      opacity 1s ease-out;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 13px;
      height: 13px;
      left: 91px;
      top: 29px;
      pointer-events: all;
    }

    &:hover {
      transform: rotate3d(-115, 33, -222, -86deg) scale(31);
      opacity: 0;
      transition:
        transform 65s 10s ease-in,
        opacity 65s 10s ease-in;
    }
  }
}
